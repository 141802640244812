<template>
  <div style="margin: 10px auto;width: 1200px;display: flex;flex-direction: row">

    <el-card class="user-nav-box">
      <div slot="header" class="clearfix" style="text-align: center;font-weight: bold;">
        <span>个人中心</span>
      </div>
      <div class="nav-list">
        <el-link href="#/my/collection">我的收藏</el-link>
        <el-link href="#/my/bookshelf">我的书架</el-link>
        <el-link class="nav-select">阅读记录</el-link>
        <el-link href="#/my/info">基本信息</el-link>
      </div>
    </el-card>

    <div style="width: 900px;">
      <h1><i class="el-icon-s-management" style="color:rgb(48, 176, 143)"></i>阅读历史</h1>
      <div class="cai-ni">
        <div class="cai-ni-box" v-for="(o, index) in bookList" :key="index">
          <div class="book-info" @click="openBookInfo(o.bookId)">
            <div style="display: flex;flex-direction: row;justify-content: flex-start">
              <el-image style="width: 100px;height:150px;border-radius: 4px;cursor: pointer"
                        fit="cover"
                        :src="global.apiUri+o.cover"></el-image>
              <div class="book-other">
                <div>
                  <p class="book-title" @click="openBookInfo(o.bookId)">{{ o.title }}</p>
                  <p>{{ o.author }} 著</p>
                  <p>分类：{{ o.cate }}</p>
                  <div class="add-time">{{ o.join_time }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <my-pagination v-show="bookList>0" ref="pagination" :currentPage="currentPage" :pageSize="pagesize"
                     :dataUrl="dataUrl"
                     :searchData="searchData" @getData="getData"></my-pagination>
      <el-empty v-show="bookList.length==0" description="暂无阅读历史"></el-empty>
    </div>
  </div>
</template>

<script>
import MyPagination from "../../components/pagination";

export default {
  name: "bookshelf",

  components: {MyPagination},
  data() {
    return {
      bookList: [],
      currentPage: 1,
      pagesize: 10,
      searchData: {},
      dataUrl: '/user/browse',
    }
  },
  created() {
    if (!window.localStorage.getItem('access_token')) {
      let redirect = decodeURIComponent('/login');
      this.$router.push({
        path: redirect
      })
    }
  },
  methods: {
    //查询
    searchSubmit() {
      this.$refs.pagination.pages = 1;
      this.currentPage = 1;
      setTimeout(() => {
        //异步执行列表刷新
        this.$refs.pagination.getTableData();
      }, 0)
    },
    //表格获取数据
    getData(e) {
      this.bookList = e;
    },
    //更新表格数据
    updateList() {
      this.$refs.pagination.getTableData();
    },
    openBookInfo(bookId) {
      this.$router.push({path: '/book/bookInfo', query: {bookId: bookId}});
    }
  },
}
</script>

<style scoped lang="less">
.home {
  width: 100%;
}

h1 {
  font-size: 18px;
}

.user-nav-box {
  width: 200px;
  margin-right: 30px;
  height: 400px;

  .nav-list {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    line-height: 40px;
  }

  .nav-select {
    font-weight: bold;
    color: #409EFF;
  }
}

.cai-ni {
  width: 960px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  .cai-ni-box {
    width: 320px;
    padding-left: 5px;
    padding-right: 5px;
    box-sizing: border-box;

    .book-info {
      width: 310px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: 20px;
      height: 150px;
      border-radius: 5px;
      border: 1px solid #E3DEDE74;
      box-sizing: border-box;
      font-size: 14px;
      cursor: pointer;

      .book-other {
        padding: 0 10px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;

        .title {
          font-size: 16px;
          font-weight: bold;
        }
      }
    }

    .right {
      width: 200px;
      padding: 30px;
      display: flex;
      flex-direction: column;
      align-items: center;
      box-sizing: border-box;
      justify-content: space-between;
    }
  }
}

.book-title {
  width: 230px;
  font-size: 16px !important;
  font-weight: bold !important;
  margin-bottom: 5px !important;
  overflow: hidden; //超出的文本隐藏
  text-overflow: ellipsis; //用省略号显示
  white-space: nowrap; //不换行
}
</style>